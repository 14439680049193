// index.js
import { Elm } from './src/Main.elm'
import { getConfig } from './config.js';
import 'fomantic-ui-css/semantic.min.css';



var apolloClient = require("apollo-client")
var apolloLinkWS = require('apollo-link-ws')
var apolloCacheInMemory = require("apollo-cache-inmemory")
var gql = require('graphql-tag')
var GRAPHQL_URI = 'gql.genomicscore.be/v1/graphql'



function getClient() {
    const wsLink = new apolloLinkWS.WebSocketLink({
        uri: `wss://${GRAPHQL_URI}`,
        options: {
            reconnect: true,
            connectionParams: { headers: { 'X-Hasura-Request-Role': 'customer' } }
        }
    });
    const client = new apolloClient.ApolloClient({
        link: wsLink,
        cache: new apolloCacheInMemory.InMemoryCache({
            addTypename: true
        })
    });
    return client;
}





document.addEventListener("DOMContentLoaded", function() {
    var app = Elm.Main.init({
        node: document.querySelector('main'),
        flags: { "serviceUrl": getConfig()["backend-url"] }
    })


    console.log("Loaded");

    app.ports.createSubscriptionToBookableResources.subscribe(function(data) {
        console.log("Received a message " + JSON.stringify(data));
        /* Initiate subscription request */

        //var [data] = data;
        getClient()
            .subscribe({
                query: gql `${data}`,
                variables: {}
            })
            .subscribe({
                next(resp) {
                    app.ports.gotBookableResources.send(resp);
                },
                error(err) {
                    console.log('error is');
                    console.log(err);
                }
            });
    });
});